import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.querySelector('body > div')
)


// Learn more about service workers: https://bit.ly/CRA-PWA
/* eslint-disable import/first */
import * as serviceWorker from './serviceWorker'
serviceWorker.register()
