import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: NotoSansMonoCJKsc;
    src: url("static/NotoSansMonoCJKsc-Regular.otf") format("opentype");
  }
  body {
    margin: 0;
    padding: 0;
    font-family: 'NotoSansMonoCJKsc', sans-serif;
  }
`;
 
export default GlobalStyle