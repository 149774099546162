import React, { lazy, Suspense, useEffect, useState } from 'react'
import { APP_MODULE_NAMES, APP_MODULE_DEFAULT_INDEX } from './constant'
import styled from 'styled-components'
import Loading from './loading'
import GlobalStyle from './GlobalStyle'

const AppView = styled.div``

function App() {

  const [Module, setModule] = useState<any>(() => Loading)

  // Mount Moudle As Needed
  // Only `window.location?.host` Changed
  const host = window?.location?.host
  useEffect(() => {

    const moduleName: string | void =
      APP_MODULE_NAMES.find(
        checkedName => host?.includes(checkedName)
      ) ??
      APP_MODULE_NAMES[APP_MODULE_DEFAULT_INDEX]

    if (moduleName) {
      setModule(lazy(() => import(`./${moduleName}/index.tsx`)))
    }

  }, [host])

  return (
    <AppView>
      <GlobalStyle/>
      <Suspense fallback={Loading}>
        <Module/>
      </Suspense>
    </AppView>
  )

}

export default App
